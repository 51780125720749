@font-face {
  font-family: var(--font-family-headline-critical-fontface);
  src:
    url("./fonts/Rando-Medium.woff2") format("woff2"),
    url("./fonts/Rando-Medium.woff") format("woff");
}

@font-face {
  font-family: var(--font-family-headline-fontface);
  src:
    url("./fonts/Rando-Medium.woff2") format("woff2"),
    url("./fonts/Rando-Medium.woff") format("woff");
}

@font-face {
  font-family: var(--font-family-copy-critical-fontface);
  font-style: normal;
  font-weight: normal;
  src:
    url("./fonts/AtlasGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/AtlasGrotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: var(--font-family-copy-fontface);
  font-style: normal;
  font-weight: normal;
  src:
    url("./fonts/AtlasGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/AtlasGrotesk-Regular.woff") format("woff");
}

@font-face {
  font-family: var(--font-family-copy-fontface);
  font-style: normal;
  font-weight: 600;
  src:
    url("./fonts/AtlasGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/AtlasGrotesk-Medium.woff") format("woff");
}

@font-face {
  font-family: var(--font-family-copy-fontface);
  font-style: normal;
  font-weight: 700;
  src:
    url("./fonts/AtlasGrotesk-Bold.woff2") format("woff2"),
    url("./fonts/AtlasGrotesk-Bold.woff") format("woff");
}
