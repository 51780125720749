.link {
  color: var(--color-navigation-toplevel);
  cursor: pointer;
  display: block;
  font-size: var(--font-size-navigation-toplevel);
  position: relative;
  text-decoration: none;
}

.root:hover,
.active,
.open,
.open + .arrow {
  color: var(--color-active-navigation-toplevel);
}

/* COMPACT */

.compact .linkWrapper {
  display: flex;
  position: relative;
}

.compact .link {
  border-bottom: 1px solid var(--color-divider-prio2);
  flex-grow: 1;
  line-height: 1.3em;
  padding: var(--space-s) var(--space-xl) var(--space-s) var(--space-m);
  position: relative;
}

.compact .link.open {
  border-bottom: 1px solid var(--color-divider-prio1);
}

.compact .child {
  margin: 0;
}

.compact .arrow {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  display: block;
  margin: 0;
  padding: var(--space-m) var(--space-s);
  position: absolute;
  right: 0;
  top: 0;
}

.compact .arrow.down {
  animation: rotateToDown 0.3s;
  backface-visibility: hidden;
}

@keyframes rotateToDown {
  0% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.compact .link:focus-visible,
.compact .arrow:focus-visible {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * calc(0.1rem + var(--focus-width)));
}

.servicePageHighlight {
  background-color: var(--grey-35);
}

.servicePageHighlight > .link {
  font-size: 1.5rem;
  font-weight: var(--font-weight-copy-semibold);
}

/* FLYOUT */

.flyout .linkWrapper {
  align-items: center;
  display: flex;
}

.flyout .link {
  border: 1px solid transparent;
  border-bottom: none;
  line-height: 1.1em;
  padding: 1.1rem 2.1rem;
  touch-action: none;
}

.flyout .arrow {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  display: flex;
  height: 24px;
  margin-left: -22px;
  opacity: 0;
  pointer-events: none;
  width: 22px;
  z-index: 1;
}

.flyout .arrow.visible,
.flyout .arrow:focus-visible {
  opacity: 1;
}

.flyout .arrow:focus-visible {
  border-radius: 0.5rem;
  color: var(--focus-text-color);
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-1 * var(--focus-width));
}

.flyout .arrow::after {
  border-bottom: 1px solid var(--black-100);
  border-right: 1px solid var(--black-100);
  content: "";
  height: 0.8rem;
  padding-left: 0.7rem;
  transform: rotate(45deg);
  width: 0.8rem;
}

.flyout .arrow.open::after {
  margin-top: 0.9rem;
  transform: rotate(225deg);
}

.flyout .child {
  border: 1px solid var(--grey-50);
  border-radius: 0 0 3px 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  display: block;
  position: absolute;
  top: 100%;

  &:empty {
    display: none;
  }
}

.flyout .cols4 {
  width: var(--grid-8-12);
}

.flyout .cols5 {
  width: var(--grid-10-12);
}

.flyout .cols6 {
  width: var(--grid-12-12);
}

.flyout .left,
.flyout .midleft {
  left: 0;
}

.flyout .right,
.flyout .midright {
  right: 0;
}

.flyout .link.open {
  background-color: var(--grey-35);
  border-color: var(--grey-50);
  border-radius: 3px 3px 0 0;
  box-shadow: 0 2px 2px 1px var(--grey-35);

  &::before {
    background-color: var(--grey-35);
    border-left: 1px solid var(--grey-50);
    border-right: 1px solid var(--grey-50);
    box-shadow: 0 2px 2px 1px var(--grey-35);
    content: "";
    display: block;
    height: 13rem;
    left: -1px;
    position: absolute;
    right: -1px;
    top: 100%;
    z-index: 10;
  }
}

.flyout .link span::before {
  background-color: var(--grey-50);
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 2.2rem;
  opacity: 0;
  position: absolute;
  right: 2.2rem;
  top: 3.31rem;
  transform: scaleX(0.5) translateZ(0);
  transition:
    transform 0.25s,
    opacity 0.2s;
  transition-timing-function: ease-in-out;
}

.flyout .link:hover span::before {
  opacity: 0.7;
  transform: scaleX(1);
}

.flyout .link.active span::before {
  opacity: 1;
  transform: scaleX(1);
}
