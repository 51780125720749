/* repetition to increase specificity against copy text definition of `.AppRoot_root *` */
.root {
  text-align: center;
}

.margin {
  @media (--up_to_L) {
    margin-bottom: calc(var(--space-xxl) - 0.8rem);
  }

  @media (--L_and_up) {
    margin-bottom: calc(var(--space-xxxl) - 0.8rem);
  }
}

.headline {
  @if $magazin {
    text-transform: uppercase;
  }

  @media (--up_to_L) {
    font-size: var(--font-size-headline-prio1);
    line-height: var(--line-height-headline-prio1);
  }

  @media (--L_and_up) {
    font-size: var(--font-size-headline-prio0);
    line-height: var(--line-height-headline-prio0);
  }

  color: var(--color-headline-prio0);
  font-family: var(--font-family-headline) !important;
}

:global(.mf-fonts-not-loaded) .headline {
  font-family: var(--font-family-headline-not-loaded);
}

:global(.mf-fonts-critical) .headline {
  font-family: var(--font-family-headline-critical);
}
