.root {
  margin-bottom: 0.2rem;
}

.visuallyHidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.root.light {
  border-top: thin solid var(--color-divider-prio2);
  margin-bottom: 0;
}

.clickArea {
  background-color: var(--grey-50);
  color: var(--grey-150);
  cursor: pointer;
  display: block;
  user-select: none;
  width: 100%;
}

.title {
  color: var(--color-headline-prio2-highcontrast);
  display: inline-block;
  font-family: var(--font-family-headline);
  font-size: var(--font-size-headline-prio2);
  line-height: var(--line-height-headline-prio2);
  margin-left: var(--space-xl);
}

.skeleton {
  animation: loading 1.5s 3s infinite ease-in-out;
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(var(--grey-75) 0.9em, transparent 0);
  background-position:
    -100% 0.1em,
    0 0.1em;
  background-repeat: no-repeat;
  background-size:
    50% 0.9em,
    100% 0.9em;
  color: transparent !important;
  display: inline-block;
  line-height: 1;
}

@keyframes loading {
  to {
    background-position:
      200% 0.1em,
      0 0.1em;
  }
}

.icon-right .title {
  margin-left: var(--space-m);
}

.light .title {
  color: var(--grey-150);
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
}

.icon {
  margin-left: var(--space-m);
  vertical-align: text-bottom;
}

:global(.mf-fonts-not-loaded) .title {
  font-family: var(--font-family-headline-not-loaded);
}

.clickArea.open .title {
  color: var(--color-headline-prio2-inverted);
}

.subtitle {
  color: var(--color-copy-normal);
  display: block;
  font-size: var(--font-size-copy-normal);
  font-weight: var(--font-weight-copy-normal);
  line-height: var(--line-height-copy-normal);
  margin: 0.2rem 0 0 var(--space-xl);
}

.subtitle.open {
  color: var(--white-100);
}

.clickArea.open {
  background-color: var(--main-100);
}

.clickArea.disabled,
.clickArea.open.disabled {
  background-color: var(--grey-50);
  color: var(--grey-100);
}

.label {
  cursor: pointer;
  display: block;
  padding: 1.2rem 0 1.2rem 2.6rem;
  width: 100%;
}

.iconContainer {
  color: var(--main-100);
  margin: 0 var(--space-m);
  position: absolute;
}

.iconContainer.disabled {
  color: transparent;
}

.open .iconContainer {
  color: var(--white-100);
}

:global(.mf-noscript) .iconContainer {
  display: none;
}

.content,
.content.open.disabled {
  display: none;
  padding: var(--space-xl) var(--grid-1-12);
}

.light .content,
.light .content.open.disabled {
  padding: 0;
}

.content.open {
  display: block;
}

:global(.mf-noscript) .content,
:global(.mf-noscript) .content.open.disabled {
  animation: avoidFlash;
  animation-duration: 1s;
  display: block;
  overflow: hidden;
}

:global(.mf-noscript) .content.open {
  animation: none;
}

@media (--XL_and_up) {
  :global(.mf-noscript) .smallAccordionItem .content {
    animation: none;
  }
}

@keyframes avoidFlash {
  from {
    height: 0;
    padding: 0;
  }

  to {
    height: 0;
    padding: 0;
  }
}

.root:last-child .content.open {
  border-bottom: 1px solid var(--color-divider-prio2);
}

.root.light:last-child .content.open {
  border-bottom: none;
}

.root.light:last-child {
  border-bottom: thin solid var(--color-divider-prio2);
}

@media (--XL_and_up) {
  .root.smallAccordionItem {
    background-color: var(--grey-50);
    flex: 1;
    margin: 0 var(--grid-gutter) 0.2rem var(--grid-gutter);
  }

  .root.smallAccordionItem:first-child {
    margin-left: 0;
  }

  .root.smallAccordionItem:last-child {
    margin-right: 0;
  }

  .smallAccordionItem .clickArea,
  .smallAccordionItem .clickArea.open {
    background-color: var(--grey-50);
    cursor: default;
    margin-bottom: 0;
    padding: var(--space-l) var(--space-xxl);
  }

  .smallAccordionItem .title {
    display: block;
    margin: 0 0 var(--space-xl) 0;
    text-align: center;
  }

  .smallAccordionItem .clickArea .title {
    color: var(--main-100);
  }

  .smallAccordionItem .subtitle {
    border-bottom: 1px solid var(--color-divider-prio2);
    display: block;
    margin: 0;
    padding-bottom: var(--space-l);
  }

  .smallAccordionItem .subtitle.open {
    color: var(--black-100);
  }

  .smallAccordionItem .content,
  :global(.mf-noscript) .smallAccordionItem .content {
    display: block;
    margin-bottom: var(--space-m);
    padding: 0 var(--space-xxl) var(--space-xl);
  }

  .smallAccordionItem .iconContainer {
    display: none;
  }

  .root.smallAccordionItem:last-child .content.open {
    border-bottom: none;
  }
}

.light .clickArea.disabled,
.light .clickArea.open.disabled {
  background-color: var(--white-100);
}

.light .clickArea {
  background-color: var(--white-100);
  color: var(--grey-150);
}

/* stylelint-disable-next-line */
.light .title,
.light .open .title {
  color: var(--grey-150);
  margin-left: calc(var(--space-m) + var(--font-size-copy-em));
}

.icon-right.light .title,
.icon-right.light .open .title {
  margin-left: 0;
}

.light .iconContainer {
  color: var(--darkGrey-105);
  margin: 0 var(--space-m) 0 0;
}

.root input[type="radio"]:checked + .clickArea,
.root input[type="radio"]:not(:checked) + .clickArea {
  cursor: pointer;
  display: inline-block;
  line-height: 2rem;
  position: relative;
}

.root input[type="radio"]:checked + .clickArea::before,
.root input[type="radio"]:not(:checked) + .clickArea::before {
  background: #ffffff;
  border: 1px solid var(--black-100);
  border-radius: 100%;
  content: "";
  height: 16px;
  margin: 1.6rem 2rem 0;
  pointer-events: none;
  position: absolute;
  width: 16px;
}

.root input[type="radio"]:checked + .clickArea::after,
.root input[type="radio"]:not(:checked) + .clickArea::after {
  background: var(--black-100);
  border-radius: 100%;
  content: "";
  height: 8px;
  margin-left: 2.4rem;
  margin-top: 2rem;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 8px;
}

.root input[type="radio"]:not(:checked) + .clickArea::after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.root input[type="radio"]:checked + .clickArea::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.root input[type="radio"]:checked:focus-visible + .clickArea {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color-inverted);
  outline-offset: calc(-2 * var(--focus-width));
}

.root input[type="radio"]:not(:checked):focus-visible + .clickArea {
  border-radius: 0;
  outline: var(--focus-width) solid var(--focus-color);
  outline-offset: calc(-2 * var(--focus-width));
}
