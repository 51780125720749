.root {
  position: relative;
}

.embla {
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slides {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slide {
  min-width: 0;
  padding: 0 calc(var(--focus-width) + var(--focus-offset));
  transform: translate3d(0, 0, 0);
}

.slide.full {
  @media (--XXL_and_up) {
    flex: 0 0 16.667%;
  }
  @media (--XL_to_XXL) {
    flex: 0 0 20%;
  }
  @media (--L_to_XL) {
    flex: 0 0 25%;
  }
  @media (--M_to_L) {
    flex: 0 0 33.333%;
  }
  @media (--up_to_M) {
    flex: 0 0 50%;
  }
}

.slide.half {
  @media (--XXL_and_up) {
    flex: 0 0 33.333%;
  }
  @media (--XL_to_XXL) {
    flex: 0 0 33.333%;
  }
  @media (--L_to_XL) {
    flex: 0 0 50%;
  }
  @media (--M_to_L) {
    flex: 0 0 50%;
  }
  @media (--up_to_M) {
    flex: 0 0 100%;
  }
}

.slide.four {
  @media (--XXL_and_up) {
    flex: 0 0 25%;
  }
  @media (--XL_to_XXL) {
    flex: 0 0 25%;
  }
  @media (--L_to_XL) {
    flex: 0 0 50%;
  }
  @media (--M_to_L) {
    flex: 0 0 33.333%;
  }
  @media (--up_to_M) {
    flex: 0 0 50%;
  }
}

.slide.overlay {
  @media (--M_and_up) {
    flex: 0 0 25%;
  }
  @media (--up_to_M) {
    flex: 0 0 50%;
  }
}

.slide.two {
  flex: 0 0 50%;
}

.skip {
  font-size: var(--font-size-copy-normal);
  left: -999999px;
  position: absolute;
}

.skip:focus-visible {
  background: var(--grey-35);
  border-color: transparent;
  color: var(--black-100);
  left: 0px;
  outline: var(--focus-width) solid var(--focus-color);
  padding: var(--space-m);
  width: 100%;
  z-index: 1;
}

.screenreaderOnly {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
