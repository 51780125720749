.root {
  @media (--XL_and_up) {
    padding: 0 !important;
  }

  background: none;
  border: none;
  cursor: pointer;
  display: block;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.text {
  @media (--up_to_XXL) {
    display: none;
  }

  @media (--XXL_and_up) {
    color: var(--color-copy-small);
    display: block;
    font-size: var(--font-size-copy-small);
    font-weight: var(--font-weight-copy-small);
    line-height: var(--line-height-copy-small);
    margin-top: var(--space-xs);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.active.strongText .text {
  font-weight: var(--font-weight-copy-bold);
}

.icon {
  @media (--L_and_up) {
    margin: 0 auto;
  }

  background-color: var(--grey-75);
  border-radius: 50%;
  color: var(--white-100);
  display: block; /* eliminiert Unterlängen, die bei display: flex/align-items: center stören. */
  height: 3.6rem;
  width: 3.6rem;
}

.root:hover .icon {
  background-color: var(--grey-100);
}

.root:active .icon {
  background-color: var(--main-100);
}

.root.active .icon {
  background-color: var(--main-100);
}

.root.active:hover .icon {
  background-color: var(--main-75);
}

.root.active:active .icon {
  background-color: var(--grey-75);
}

.icon path,
.icon polygon,
.icon ellipse {
  fill: currentColor; /* entspricht color: inherit */
}
